export default [
        {
        name: 'Listar Marca Veiculo',
        path: '/veiculos/marca',
        component: () => import('@/views/pages/veiculos/VeiculosMarca'),
        meta: { somenteAutenticado: true },
      },
      {
        name: 'Listar Modelo Veiculo',
        path: '/veiculos/modelo',
        component: () => import('@/views/pages/veiculos/VeiculosModelo'),
        meta: { somenteAutenticado: true },
      },
      {
        name: 'Adicionar Modelo Veiculo',
        path: '/veiculos/modelo/adicionar',
        component: () => import('@/views/pages/veiculos/AdicionarModeloVeiculo'),
        meta: { somenteAutenticado: true },
      },
      {
        name: 'Adicionar Marca Veiculo',
        path: '/veiculos/marca/adicionar',
        component: () => import('@/views/pages/veiculos/AdicionarMarcaVeiculo'),
        meta: { somenteAutenticado: true },
      }, 
      {
        name: 'Editar Modelo Veiculo',
        path: '/veiculos/modelo/:id/editar',
        component: () => import('@/views/pages/veiculos/EditarModeloVeiculo'),
        meta: { somenteAutenticado: true },
      },
      {
        name: 'Editar Marca Veiculo',
        path: '/veiculos/marca/:id/editar',
        component: () => import('@/views/pages/veiculos/EditarMarcaVeiculo'),
        meta: { somenteAutenticado: true },
      }, 
  ]
  

