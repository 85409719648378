export default {
  namespaced: true,
  state: {
    leiturasSelecionadas: [],
    filtros: {},
    resultado: {},
  },
  mutations: {
    SET_LEITURAS_SELECIONADAS (state, payload) {
      console.log(payload, 'SET_LEITURAS_SELECIONADAS payload')
      console.log(state, 'SET_LEITURAS_SELECIONADAS state')
      state.leiturasSelecionadas = payload
    },
    SET_RESULTADO (state, payload) {
      state.resultado = payload
    },
    SET_FILTROS (state, payload) {
      state.filtros = payload
    },
  },
}
