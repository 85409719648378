export default [
  {
    name: 'Listar Situações Ocorrência',
    path: '/situacoes-ocorrencia',
    component: () => import('@/views/pages/situacoes-ocorrencia/SituacoesOcorrenciaListar'),
    meta: { somenteAutenticado: true },
  },
  {
    name: 'Adicionar Situações Ocorrência',
    path: '/situacoes-ocorrencia/adicionar',
    component: () => import('@/views/pages/situacoes-ocorrencia/SituacoesOcorrenciaAdicionar'),
    meta: { somenteAutenticado: true },
  },
  {
    name: 'Editar Situações Ocorrência',
    path: '/situacoes-ocorrencia/:id/editar',
    component: () => import('@/views/pages/situacoes-ocorrencia/SituacoesOcorrenciaEditar'),
    meta: { somenteAutenticado: true },
  },
]
