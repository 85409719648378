export default [
  {
    name: 'Listar Naturezas Ocorrência',
    path: '/naturezas-ocorrencia',
    component: () => import('@/views/pages/naturezas-ocorrencia/NaturezasOcorrenciaListar'),
    meta: { somenteAutenticado: true },
  },
  {
    name: 'Adicionar Naturezas Ocorrência',
    path: '/naturezas-ocorrencia/adicionar',
    component: () => import('@/views/pages/naturezas-ocorrencia/NaturezasOcorrenciaAdicionar'),
    meta: { somenteAutenticado: true },
  },
  {
    name: 'Editar Naturezas Ocorrência',
    path: '/naturezas-ocorrencia/:id/editar',
    component: () => import('@/views/pages/naturezas-ocorrencia/NaturezasOcorrenciaEditar'),
    meta: { somenteAutenticado: true },
  },
]
