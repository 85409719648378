<template>
  <router-link
    :to="href"
    class="text-decoration-none"
  >
    <v-btn
      icon
      small
    >
      <v-icon
        small
        v-text="'mdi-pencil'"
      />
    </v-btn>
  </router-link>
</template>

<script>
  export default {

    props: {
      href: {
        type: String,
        default: '',
      },
    },

  }
</script>
