export default [
  {
    name: 'Listar Ocorrências',
    path: '/ocorrencias',
    component: () => import('@/views/pages/ocorrencias/OcorrenciasListar'),
    meta: { somenteAutenticado: true },
  },
  {
    name: 'Adicionar Ocorrências',
    path: '/ocorrencias/adicionar',
    component: () => import('@/views/pages/ocorrencias/OcorrenciasAdicionar'),
    meta: { somenteAutenticado: true },
  },
  {
    name: 'Editar Ocorrências',
    path: '/ocorrencias/:id/editar',
    component: () => import('@/views/pages/ocorrencias/OcorrenciasEditar'),
    meta: { somenteAutenticado: true },
  },
]
