<template>
  <v-btn
    color="secondary"
    :to="to"
  >
    <v-icon
      left
      v-text="'mdi-chevron-left'"
    />
    Voltar
  </v-btn>
</template>

<script>
  export default {

    props: {
      to: {
        type: String,
        default: '/',
      },
    },

  }
</script>
