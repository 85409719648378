export default [
  {
    name: 'Listar Pontos de Captura',
    path: '/pontos-captura',
    component: () => import('@/views/pages/pontos-captura/PontosCapturaListar'),
    meta: { somenteAutenticado: true },
  },
  {
    name: 'Adicionar Pontos de Captura',
    path: '/pontos-captura/adicionar',
    component: () => import('@/views/pages/pontos-captura/PontosCapturaAdicionar'),
    meta: { somenteAutenticado: true },
  },
  {
    name: 'Editar Pontos de Captura',
    path: '/pontos-captura/:id/editar',
    component: () => import('@/views/pages/pontos-captura/PontosCapturaEditar'),
    meta: { somenteAutenticado: true },
  },
  {
    name: 'Status por Ponto de Captura',
    path: '/pontos-captura/status-por-ponto-captura',
    component: () => import('@/views/pages/pontos-captura/status-cameras/StatusPorPontoCaptura'),
    meta: { somenteAutenticado: true },
  },
]
