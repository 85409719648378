export default [
  {
    name: 'Relatório Proximidade - Filtros',
    path: '/relatorios/proximidade/filtros',
    component: () => import('@/views/pages/relatorios/proximidade/ProximidadeFiltros'),
    meta: { somenteAutenticado: true },
  },
  {
    name: 'Relatório Proximidade - Análise',
    path: '/relatorios/proximidade/analise',
    component: () => import('@/views/pages/relatorios/proximidade/ProximidadeAnalise'),
    meta: { somenteAutenticado: true },
  },
  {
    name: 'Relatório Estatísticas',
    path: '/relatorios/estatisticas',
    component: () => import('@/views/pages/relatorios/estatisticas/Estatisticas'),
    meta: { somenteAutenticado: true },
  },
  {
    name: 'Relatório Leituras Sem Placa',
    path: '/relatorios/leituras-sem-placa',
    component: () => import('@/views/pages/relatorios/leituras-sem-placa/LeiturasSemPlaca'),
    meta: { somenteAutenticado: true },
  },
  {
    name: 'Relatório Placas Corrigidas',
    path: '/relatorios/placas-corrigidas',
    component: () => import('@/views/pages/relatorios/placas-corrigidas/PlacasCorrigidas'),
    meta: { somenteAutenticado: true },
  },
  {
    name: 'Relatório Visualização de Acessos',
    path: '/relatorios/visualizacao-acessos',
    component: () => import('@/views/pages/relatorios/visualizacao-acessos/VisualizacaoAcessos'),
    meta: { somenteAutenticado: true },
  },
]
