import Vue from 'vue'

import BtnAdicionar from '@/components/button/ButtonAdicionar'
import BtnCancelar from '@/components/button/ButtonCancelar'
import BtnSalvar from '@/components/button/ButtonSalvar'
import BtnVoltar from '@/components/button/ButtonVoltar'
import BtnFiltros from '@/components/button/ButtonFiltros'

import TableBtnEditar from '@/components/table/TableButtonEditar'
import TableBtnSee from '@/components/table/TableButtonSee'
import TableBtnDeletar from '@/components/table/TableButtonDeletar'

Vue.component('btn-adicionar', BtnAdicionar)
Vue.component('btn-cancelar', BtnCancelar)
Vue.component('btn-salvar', BtnSalvar)
Vue.component('btn-voltar', BtnVoltar)
Vue.component('btn-filtros', BtnFiltros)

Vue.component('table-btn-editar', TableBtnEditar)
Vue.component('table-btn-deletar', TableBtnDeletar)
Vue.component('table-btn-see', TableBtnSee)
