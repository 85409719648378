export default {
  namespaced: true,

  state: {
    showFilters: false,
  },

  mutations: {
    SET_SHOW_FILTERS (state, showFilters) {
      state.showFilters = showFilters
    },
  },

  actions: {
    setShowFilters({ commit }, showFilters) {
      commit('SET_SHOW_FILTERS', showFilters)
    },
  }
}
