export default [
  {
    name: 'Listar Alarmes',
    path: '/alarmes',
    component: () => import('@/views/pages/alarmes/AlarmesListar'),
    meta: { somenteAutenticado: true },
  },
  {
    name: 'Histórico Alarmes',
    path: '/alarmes/:id/historico',
    component: () => import('@/views/pages/alarmes/AlarmesHistorico'),
    meta: { somenteAutenticado: true },
  },
]
