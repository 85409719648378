export default [
  {
    name: 'Listar Empresas',
    path: '/empresas',
    component: () => import('@/views/pages/empresas/EmpresasListar'),
    meta: { somenteAutenticado: true },
  },
  {
    name: 'Adicionar Empresa',
    path: '/empresas/adicionar',
    component: () => import('@/views/pages/empresas/EmpresasAdicionar'),
    meta: { somenteAutenticado: true },
  },
  {
    name: 'Editar Empresa',
    path: '/empresas/:id/editar',
    component: () => import('@/views/pages/empresas/EmpresasEditar'),
    meta: { somenteAutenticado: true },
  },
]
