import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import pt from '@/utils/traducao-vuetify-pt-br'
import '@/sass/overrides.sass'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { pt },
    current: 'pt',
  },
})
