import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/index'

import usuarios from './modules/usuarios'
import grupos from './modules/grupos'
import empresas from './modules/empresas'
import situacoesOcorrencia from '@/router/modules/situacoes-ocorrencia'
import naturezasOcorrencia from '@/router/modules/naturezas-ocorrencia'
import tiposOcorrencia from './modules/tipos-ocorrencia'
import pontosCaptura from './modules/pontos-captura'
import ocorrencias from './modules/ocorrencias'
import alarmes from './modules/alarmes'
import veiculos from './modules/veiculos'
import relatorios from './modules/relatorios'
import alertasPerguntas from './modules/alertas-perguntas'

Vue.use(Router)

const routes = [
  {
    name: 'Login',
    path: '/login',
    component: () => import('@/views/pages/Login'),
    meta: { redirecionarSeAutenticado: true },
  },
  
  {
    path: '/',
    component: () => import('@/views/Index'),
    children: [
      {
        name: 'Dashboard',
        path: '',
        component: () => import('@/views/pages/dashboard/Dashboard'),
        meta: { somenteAutenticado: true },
      },
      ...ocorrencias,
      ...alarmes,
      ...usuarios,
      ...grupos,
      ...empresas,
      ...situacoesOcorrencia,
      ...alertasPerguntas,
      ...naturezasOcorrencia,
      ...tiposOcorrencia,
      ...pontosCaptura,
      ...relatorios,
      ...veiculos,
    ],
  },
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  var token = store.state.usuario.token

  if (to.matched.some(record => record.meta.somenteAutenticado)) {
    if (!token) {
      next({ path: '/login' })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.redirecionarSeAutenticado)) {
    if (token) {
      next({ path: '/' })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
