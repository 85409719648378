export default [
  {
    name: 'Listar Alertas Perguntas',
    path: '/alertas-perguntas',
    component: () => import('@/views/pages/alertas-perguntas/AlertasPerguntasListar'),
    meta: { somenteAutenticado: true },
  },
  {
    name: 'Adicionar Alertas Perguntas',
    path: '/alertas-perguntas/adicionar',
    component: () => import('@/views/pages/alertas-perguntas/AlertasPerguntasAdicionar'),
    meta: { somenteAutenticado: true },
  },
  {
    name: 'Editar Alertas Perguntas',
    path: '/alertas-perguntas/:id/editar',
    component: () => import('@/views/pages/alertas-perguntas/AlertasPerguntasEditar'),
    meta: { somenteAutenticado: true },
  },
]
