export default {
  namespaced: true,
  state: {
    token: null,
    usuario: null,
  },
  getters: {
    usuarioAdministrador: (state) => {
      return state.usuario.grupos.includes('Administrador')
    },
    usuarioOperador: (state) => {
      return state.usuario.grupos.includes('Operador')
    },
    usuarioInteligencia: (state) => {
      return state.usuario.grupos.includes('Inteligência')
    },
    temPermissao: (state) => (nome) => {
      if (!state.usuario) {
        return false
      }
  
      const permissao = state.usuario.permissoes.filter((p) => p.name === nome)

      return (permissao.length > 0)
    },
  },
  mutations: {
    SET_TOKEN (state, payload) {
      state.token = payload
    },
    SET_USUARIO (state, payload) {
      state.usuario = payload
    },
    SET_IMAGEM_NOVA (state, payload) {
      state.usuario.imagem = payload
    }
  },
}
