var lodash = require('lodash/fp/object')

export default {
  install: (Vue) => {
    Vue.prototype.$erroApi = {
      _: function (erro) {
        // Timeout
        if (erro.code === 'ECONNABORTED' || erro.message === 'Network Error') {
          return 'Erro ao comunicar com o servidor, verifique sua conexao com a internet'
        }

        // A requisição foi feita e o servidor respondeu com um status fora do range 2xx
        if (erro.response) {
          // Verifica se tem alguma mensagem para exibir
          try {
            if (lodash.get(erro, 'response.data.erro.mensagem', false) !== false) {
              return erro.response.data.erro.mensagem
            }
          } catch (e) {

          }

          // Se não tiver define alugumas mensagens padrões de acordo com o status retornado
          if (erro.response.status === 404) {
            return 'Não encontrado'
          } else if (erro.response.status === 401) {
            return 'Não autorizado'
          }
        }

        return null
      },

      validacao (erro) {
        if (erro.response.status !== 422) {
          return false
        }

        return erro.response.data.erro.erros_validacao || false
      },
    }
  },
}
