<template>
  <router-link
    :to="href"
    class="text-decoration-none"
  >
    <v-btn
      icon
      small
      v-b-modal="this.ocorrencia.id.toString()"
    >
      <v-icon
        small
        v-text="'mdi-eye'"
      />
    </v-btn>
    <b-modal size="xl" class="modal-lg" :id=this.ocorrencia.id.toString() :title="ocorrencia.id.toString()" ok-only>
      <ocorrencias-campos-see
        :carregando-salvar="false"
        :carregando-buscar="false"
        :validacao-formulario-ocorrencia="validacaoFormularioOcorrencia"
        :ocorrencia="ocorrencia"
      />
    </b-modal>
  </router-link>
</template>

<script>
  export default {
    components: {
      OcorrenciasCamposSee: () => import("@/views/pages/ocorrencias/OcorrenciasCamposSee"),
    },
    props: {
      href: {
        type: String,
        default: '',
      },
      ocorrencia: {
        type: Object,
        default: {},
      },
      path: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
        validacaoFormularioOcorrencia: {},
      }
    },
    methods: {},
  }
</script>
