import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import layout from '@/store/modules/layout'
import usuario from '@/store/modules/usuario'
import alarmes from '@/store/modules/alarmes'
import relatorioProximidade from '@/store/modules/relatorio-proximidade'
import filters from '@/store/modules/filters'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    layout,
    usuario,
    alarmes,
    relatorioProximidade,
    filters
  },
  actions: {
    logout ({ commit }) {
      commit('usuario/SET_TOKEN', null)
      commit('usuario/SET_USUARIO', null)
      commit('layout/SET_SNACKBAR', { mostrar: false })
    },
    atualizarImagem({ commit }, imagemNova) {
      commit('usuario/SET_IMAGEM_NOVA', imagemNova)
    },
  },
  plugins: [createPersistedState({
    key: 'vuex',
    reducer (val) {
      if (val.usuario.token === null) { // limpa tudo ao fazer o logout
        return {}
      }

      return {
        usuario: val.usuario, // salva somente o modulo do usuario
        relatorioProximidade: val.relatorioProximidade
      }
    },
  })],
})
