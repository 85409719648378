import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import vuetify from './plugins/vuetify'
import snackbar from './plugins/snackbar'
import mensagens from '@/plugins/mensagens'
import erroApi from '@/plugins/erro-api'
import permissao from '@/plugins/permissao'
import VueExcelEditor from 'vue-excel-editor'
import { VueMaskDirective } from 'v-mask'
import '@/plugins/vee-validate'
import '@/plugins/componentes'
import '@/plugins/filters'
import '@/utils/google-maps'

Vue.use(VueExcelEditor)
Vue.use(snackbar)
Vue.use(mensagens)
Vue.use(erroApi)
Vue.use(permissao)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.directive('mask', VueMaskDirective)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
