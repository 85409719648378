export default [
  {
    name: 'Listar Tipos Ocorrência',
    path: '/tipos-ocorrencia',
    component: () => import('@/views/pages/tipos-ocorrencia/TiposOcorrenciaListar'),
    meta: { somenteAutenticado: true },
  },
  {
    name: 'Adicionar Tipos Ocorrência',
    path: '/tipos-ocorrencia/adicionar',
    component: () => import('@/views/pages/tipos-ocorrencia/TiposOcorrenciaAdicionar'),
    meta: { somenteAutenticado: true },
  },
  {
    name: 'Editar Tipos Ocorrência',
    path: '/tipos-ocorrencia/:id/editar',
    component: () => import('@/views/pages/tipos-ocorrencia/TiposOcorrenciaEditar'),
    meta: { somenteAutenticado: true },
  },
]
