export default {
  namespaced: true,
  state: {
    quantidadeAlarmesNaoAssinados: 0,
  },
  mutations: {
    SET_QUANTIDADE_ALARMES_NAO_ASSINADOS (state, payload) {
      state.quantidadeAlarmesNaoAssinados = payload
    },
  },
}
