<template>
  <v-btn
    color="info"
    class="white--text"
    @click="$emit('click')"
  >
    <v-icon
      left
      v-text="'mdi-filter'"
    />
    Filtros
  </v-btn>
</template>

<script>
  export default {

  }
</script>
