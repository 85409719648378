<template>
  <v-btn
    color="grey"
    class="white--text"
    :text="text"
    :outlined="outlined"
    @click="$emit('click')"
  >
    <v-icon
      left
      v-text="'mdi-cancel'"
    />
    Cancelar
  </v-btn>
</template>

<script>
  export default {

    props: {
      carregando: {
        type: Boolean,
        default: false,
      },
      text: {
        type: Boolean,
        default: false,
      },
      outlined: {
        type: Boolean,
        default: false,
      },
    },

  }
</script>
