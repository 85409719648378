<template>
  <v-btn
    color="green"
    class="white--text" 
    :loading="carregando"
    v-bind="$props"
    @click="$emit('click')"
  >
    <v-icon
      left
      v-text="'mdi-content-save'"
    />
    Salvar
  </v-btn>
</template>

<script>
  export default {

    props: {
      carregando: {
        type: Boolean,
        default: false,
      },
      text: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      outlined: {
        type: Boolean,
        default: false,
      },
    },

  }
</script>
